import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { ToastService } from '@intemp/unijob-ui2';
import { I18NextPipe } from 'angular-i18next';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from 'apollo-angular/http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Client, createClient } from 'graphql-ws';
// @ts-ignore: Missing type definitions
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore: Missing type definitions
import isExtractableFile from 'extract-files/isExtractableFile.mjs';
import { applyToken } from '../../shared/helpers/functions/applyToken';
import { environment } from '../../../environments/environment';
import { UserService } from '../../models/shared/user/user.service';
import { OverlayService } from '../../shared/modules/overlay/overlay.service';

/**
 * Starts pooling the health endpoint of the server until it becomes healthy.
 */
function waitForHealthy(uri: string) {
  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(async () => {
      console.log('Waiting for server to become healthy...');
      try {
        const res = await fetch(uri);
        if (res.status === 200) {
          console.log('Server is healthy!');
          clearInterval(interval);
          resolve();
        }
      } catch (err) {
        // ignore
      }
    }, 1000);
  });
}

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  constructor(
    private toastService: ToastService,
    private i18nPipe: I18NextPipe,
    private router: Router,
  ) {}

  wsClient?: Client;

  public getApolloFactory(uri: string, wsUri: string) {
    return (
      httpLink: HttpLink,
      userService: UserService,
      toastService: ToastService,
      i18nPipe: I18NextPipe,
      graphQlService: GraphQLService,
      overlayService: OverlayService,
    ): ApolloClientOptions<any> => {
      const errorLink = onError((err) => {
        graphQlService.handleError(err);
      });

      this.wsClient = createClient({
        url: wsUri,
        retryWait: async function waitForServerHealthyBeforeRetry() {
          overlayService.show('cannotReachServerAttemtingToReconnect');
          await waitForHealthy(environment.apiHealthUrl);

          await new Promise((resolve) =>
            setTimeout(resolve, 3000 + Math.random() * 3000),
          );

          overlayService.hide();
          location.reload();
        },
        connectionParams: async () => {
          const newToken = await userService.getAuthToken();
          return {
            Authorization: `Bearer ${applyToken(newToken)}`,
          };
        },
      });

      const linkForWs = new GraphQLWsLink(this.wsClient);

      const auth = setContext(async (_, { headers }) => {
        const newToken = await userService.getAuthToken();
        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${applyToken(newToken)}`,
          },
        };
      });

      const linkForHttp = ApolloLink.from([
        errorLink,
        auth,
        httpLink.create({
          uri,
          // @ts-ignore: Missing type definitions
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
      ]);

      const splitLink = split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        linkForWs,
        linkForHttp,
      );

      const cache = new InMemoryCache({
        addTypename: false,
        typePolicies: {
          User: {
            keyFields: ['_id'],
          },
          PersonalContactInformation: {
            merge: false,
          },
        },
      });

      return {
        link: splitLink,
        cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
          },
          query: {
            fetchPolicy: 'no-cache',
          },
        },
      };
    };
  }

  handleError(err?: ApolloError | ErrorResponse | null) {
    const logBlacklist = ['Login required'];

    // log error
    if (err instanceof Error && !logBlacklist.includes(err.message)) {
      console.log(err);
    }

    let handled = false;

    // graphQL errors
    if (err?.graphQLErrors) {
      err.graphQLErrors.forEach((graphQLError) => {
        if (
          // this error usually indicates a server side user fetching error, in the past these included users logged in with another auth0 tenant
          graphQLError?.extensions?.code === 'INTERNAL_SERVER_ERROR' &&
          graphQLError?.message === 'No mongoUser found in req of context'
        ) {
          this.router.navigate(['/logout']).then();
          handled = true;
        } else if (
          // this error seams to indicate that the authentication layer within the api was not ready, this is handled elsewhere
          graphQLError?.extensions?.code === 'INTERNAL_SERVER_ERROR' &&
          graphQLError?.message === 'No user found in req of context'
        ) {
          handled = true;
        } else if (
          graphQLError?.extensions?.code === 'VALIDATION_FAILED' &&
          graphQLError?.extensions?.validationMessages &&
          Array.isArray(graphQLError?.extensions?.validationMessages) &&
          graphQLError?.extensions?.validationMessages.length > 0
        ) {
          const validationMessages =
            graphQLError?.extensions?.validationMessages;
          for (const validationMessage of validationMessages) {
            if (
              validationMessage?.i18n &&
              typeof validationMessage?.i18n === 'string'
            ) {
              this.toastService.makeToast({
                type: 'ERROR',
                message: this.i18nPipe.transform(validationMessage.i18n),
              });
              handled = true;
            } else if (
              validationMessage?.message &&
              typeof validationMessage?.message === 'string'
            ) {
              this.toastService.makeToast({
                type: 'ERROR',
                message: validationMessage.message,
              });
              handled = true;
            }
          }
        } else if (graphQLError.message === 'Unauthorized') {
          // this means the jwt token is invalid or expired and the user needs to login again
          // this is a last resort, must keep-alives and refreshes should happen before this
          window.location.reload();
          handled = true;
        } else {
          this.toastService.makeToast({
            type: 'ERROR',
            message: this.i18nPipe.transform(
              (graphQLError?.extensions?.translationKey as string) ??
                graphQLError.message ??
                'serverError',
            ),
          });
          handled = true;
        }
      });
      err.graphQLErrors.forEach((graphQLError) => {
        console.log(graphQLError);
        handled = true;
      });
    }

    // network error
    if (err?.networkError) {
      this.toastService.makeToast({
        type: 'ERROR',
        message:
          err.networkError.message ?? this.i18nPipe.transform('serverError'),
      });
      handled = true;
    }

    // general error
    if (!handled && err instanceof Error && err.message) {
      this.toastService.makeToast({
        type: 'ERROR',
        message: err.message ?? this.i18nPipe.transform('serverError'),
      });
      handled = true;
    }

    // unhandled error
    if (!handled) {
      this.toastService.makeToast({
        type: 'ERROR',
        message: this.i18nPipe.transform('serverError'),
      });
    }
  }
}
