/**
 * For list subscriptions the API will send a message with the following structure:
 * {
 *  items: T[];
 *  changedItems: T[];
 *  removedItems: T[];
 *  addedItems: T[];
 *  totalCount: number;
 * }
 *
 * Only the first message will contain all items, subsequent messages will only contain changes.
 *
 * This function will merge the changes into the current list of items.
 *
 * This will still return all the alterations, so that you could for example visually show that things were added, removed, or updated separately.
 */

import { Option, pipe } from 'effect';

interface SubscriptionMessage<Item, MinimalItem> {
  items: ReadonlyArray<Item & MinimalItem>;
  itemIds: ReadonlyArray<string>;
  changedItems: ReadonlyArray<Item & MinimalItem>;
  removedItems: ReadonlyArray<string>;
  addedItems: ReadonlyArray<Item & MinimalItem>;
  totalCount?: number | null;
}

export function applySubscriptionChangesToItems<
  K extends string,
  T extends SubscriptionMessage<
    T['items'][0],
    {
      [P in K]: string;
    }
  >,
>(acc: T, message: T, key: K): T {
  // Construct a new list of items based on itemIds to ensure correct order
  const newItems = message.itemIds
    .map((id) => {
      // Find if the item is newly added
      const addedItem = message.addedItems.find((item) => item[key] === id);
      if (addedItem) return addedItem;

      // Otherwise, return the existing item or undefined if it's removed
      return acc.items.find((item) => item[key] === id);
    })
    .filter((item) => item !== undefined) as T['items'];

  // Apply changes to the items as before
  const updatedItems = newItems.map((item) =>
    pipe(
      message.changedItems.findIndex(
        (changedItem) => changedItem[key] === item[key],
      ),
      (index) => (index >= 0 ? Option.some(index) : Option.none()),
      Option.map((index): T['changedItems'][0] => message.changedItems[index]),
      Option.getOrElse(() => item),
    ),
  );

  // Filter out removed items
  const finalItems = updatedItems.filter(
    (item) =>
      !message.removedItems.some((removedId) => removedId === item[key]),
  );

  // Return the updated message structure
  return {
    ...message,
    items: finalItems,
  };
}
