import { isTestingEnvironment } from './isTestingEnvironment';

/**
 * This method is used to replace the auth token with the userId from cypress
 */
export function applyToken(token: string) {
  if (isTestingEnvironment()) {
    return localStorage.getItem('cypressUserToken');
  }
  return token;
}
