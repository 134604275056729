import { CustomerBranch } from '../../../../graphql/generated';
import { SelectOption } from '@intemp/unijob-ui/lib/components/select-input/select-input.component';
import { TextOption } from '@intemp/unijob-ui2';

export function customerBranchToSelectOption(
  customerBranch: Pick<CustomerBranch, 'name' | 'uuid'>,
): SelectOption {
  return {
    name: customerBranch.name,
    value: customerBranch.uuid,
  };
}
export function customerBranchToSelectOption2(
  customerBranch: Pick<CustomerBranch, 'name' | 'uuid'>,
): TextOption {
  return {
    value: customerBranch.uuid,
    label: customerBranch.name,
  };
}
